"use client";

import { useContext, useEffect, useState, Fragment } from "react";
import { useRouter } from "next/navigation";
import { useCookies } from "react-cookie";

import { Button } from "@/components/Button";
import { Hamburger } from "@/components/Hamburger";
import { Wrapper } from "@/components/Wrapper";
import { Logo } from "@/components/Logo";

import { getSiteInfo } from "@/sites";

import { isClient } from "@/utils/browser";
import { paths } from "@/utils/paths";

import { AMPLITUDE_EVENT_NAME } from "@/internals/constants/events";
import { AUTHORIZATION } from "@/internals/constants/cookies";

import { LayoutContext } from "@/contexts/LayoutContext";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import { amplitudeTrackEvent } from "@/services/AmplitudeService/AmplitudeService";

import { useLogin } from "@/hooks/auth/useLogin";

import { HeaderProps } from "./types";

import "./styles.scss";
import { SiteName } from "@/sites/types";

export const Header = ({ noNav }: HeaderProps) => {
  const layoutContext = useContext(LayoutContext);
  const { setSource } = useContext(AmplitudeContext);

  const { isUserLoggedIn } = useLogin();
  const [cookies] = useCookies();
  const router = useRouter();

  const [isRegisterPage, setIsRegisterPage] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);

  const { siteBlackLogo28 } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN!) || {};
  const location = isClient() ? window.location : undefined;

  const navigateTo = (path: string) => {
    router.push(path);
    layoutContext?.setIsMenuOpened(false);
  };

  useEffect(() => {
    setIsRegisterPage(location?.pathname.includes("register"));
    setIsUserLogged(Boolean(isUserLoggedIn()));
  }, []);

  useEffect(() => {
    if (cookies?.[AUTHORIZATION]) {
      setIsUserLogged(true);
    } else {
      setIsUserLogged(false);
    }
  }, [cookies]);

  const { siteName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN);
  return (
    <header
      data-testid="header"
      className="header">
      <Wrapper customClass="header__wrapper">
        <Logo
          href="/"
          src={siteBlackLogo28}
          siteName={siteName}
          dataQa="header-logo-link"
          dataTestId="header-logo"
          className="header__logo"
          setSource={setSource}
        />
        {!noNav && (
          <div className="nav__wrapper">
            <Fragment>
              {!isRegisterPage && (
                <Hamburger>
                  {isUserLogged ? (
                    <Button
                      dataQA="header-dashboard-button"
                      tertiary
                      className="account"
                      label="Go to my account"
                      onClick={() => navigateTo(paths.myQrCodes())}
                      rounded={siteName === SiteName.QRNOW}
                    />
                  ) : (
                    <Fragment>
                      <Button
                        ghost
                        buttonId="login"
                        dataQA="header-login-button"
                        label="Login"
                        onClick={() => {
                          amplitudeTrackEvent(AMPLITUDE_EVENT_NAME.view_landing_login, {
                            source: "navbar",
                          });
                          router.push(paths.login());
                        }}
                      />
                      <Button
                        tertiary
                        className="signup"
                        label="Sign up"
                        dataQA="header-sign-up-button"
                        onClick={() => {
                          amplitudeTrackEvent(AMPLITUDE_EVENT_NAME.view_landing_signup, {
                            source: "navbar",
                          });
                          router.push(paths.signup());
                        }}
                        rounded={siteName === SiteName.QRNOW}
                      />
                    </Fragment>
                  )}
                </Hamburger>
              )}
            </Fragment>
          </div>
        )}
      </Wrapper>
    </header>
  );
};
